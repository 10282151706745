import axios from 'axios';
import { store } from "../redux";
import settings from '../settings';

export class RunService{
    ws_id = store.getState().localStore.wsid;
    token = store.getState().localStore.token;
    api = axios.create({
        baseURL: process.env.REACT_APP_API_LINK,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'x-es-token': this.token,
            'x-es-wsid': this.ws_id,
            'x-jwt-token': localStorage.getItem('es_actual_token'),
        },
    });

    async getTables(vname, vvalue){
        try{
            const response = await this.api.get(`/run/tables?vname=${vname}&vvalue=${vvalue}`);
            if(response.status !== 200){
                window.location.href = '/logout';
            }
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getTableData(table, source){
        try{
            const response = await this.api.get(`/run/table_data?table=${table}&source=${source}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getVariables(table){
        try{
            const response = await this.api.get(`/run/variable_names?table=${table}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }


    async getFiles(table, vname, vvalue){
        try{
            const response = await this.api.get(`/run/files?table=${table}`);
            console.log(response.data)
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async queryFiles(table, vname, vvalue){
        try{
            const response = await this.api.get(`/run/query?table=${table}&vname=${vname}&vvalue=${vvalue}`);
            console.log(response.data)
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async createRun(data){
        try{
            const response = await this.api.post('/run', data);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async createAction(runId, data){
        try{
            const response = await this.api.post(`/run/${runId}/action`, data);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getDataForQb(runId, actionId){
        try{
            console.log(`/run/${runId}/step/${actionId}/ping`)
            const response = await this.api.get(`/run/${runId}/step/${actionId}/ping`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getSchema(){
        try{
            const response = await this.api.get('/run/schema');
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async sendToDataAnalytics(data){
        try{
            const response = await axios.put(`${settings.dataAnalyticsUrl}/function/navigation/`, data);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getDataLakeBuckets(){
        try{
            const response = await this.api.get('/run/buckets');
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getDataLakeFolders(bucket){
        try{
            const response = await this.api.get(`/datalake/objectstorage/list/${bucket}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getDataLakeInnerFolders(bucket, folder){
        try{
            console.log(`/datalake/objectstorage/list/${bucket}/?obj_name=${folder}`)
            const response = await this.api.get(encodeURI(`/datalake/objectstorage/list/${bucket}?obj_name=${folder}`));
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getRuns(skip = 0, limit = 100){
        try{
            const response = await this.api.get('/run?skip=' + skip + '&limit=' + limit);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getRun(runId){
        try{
            const response = await this.api.get(`/run/${runId}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getAction(runId, actionId){
        try{
            const response = await this.api.get(`/run/${runId}/${actionId}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async nextStep(runId){
        try{
            const response = await this.api.put(`/run/${runId}/next`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getDAToken(){
        try{
            const response = await axios.post(`${settings.dataAnalyticsUrl}/save/token/`, {
                token: localStorage.getItem('es_actual_token')
            });
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async deleteRun(runId){
        try{
            const response = await this.api.delete(`/run/${runId}`);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }
}