import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Input, FormGroup, Label,
    Row, Col,
    CardFooter,
    Table
 } from 'reactstrap';
import { WorkflowsService } from '../../services/workflowsService';
import { RunService } from '../../services/runService';
import { AuthService } from '../../services/authService';
import { analytics_functions } from '../../analytics-functions';
import { useNavigate, useParams } from 'react-router-dom';
import { set } from 'react-hook-form';

const RunWorkflow = () => {
    const [workflows, setWorkflows] = useState([]);
    const [run, setRun] = useState({});
    const [workflow, setWorkflow] = useState({});
    const [openActionModal, setOpenActionModal] = useState(false);
    const [openDataModal, setOpenDataModal] = useState(false);
    const [action, setAction] = useState({});
    const [runAction, setRunAction] = useState({});
    const [table, setTable] = useState('');
    const [datasets, setDatasets] = useState([]);
    const [filterVariable, setFilterVariable] = useState('');
    const [filterVariableValue, setFilterVariableValue] = useState('');
    const [tables, setTables] = useState([]);
    const [variables, setVariables] = useState([]);
    const [files, setFiles] = useState([]);
    const [schema, setSchema] = useState('');
    const [buckets, setBuckets] = useState([]);
    const [dlfolders, setDLFolders] = useState([]);
    const [dlfiles, setDLFiles] = useState([]);
    const [usingDL, setUsingDL] = useState(false);
    const [selectedBucket, setSelectedBucket] = useState('');
    const [selectedFolder, setSelectedFolder] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [runActions, setRunActions] = useState([]);
    const { run_id } = useParams();
    const [openFileData, setOpenFileData] = useState([]);

    const [resultVariables, setResultVariables] = useState([]);
    const [varResults, setVarResults] = useState([]);
    const [displayColumns, setDisplayColumns] = useState([]);


    const toggleOpenActionModal = () => {
        setOpenActionModal(!openActionModal);
    }
    const toggleOpenDataModal = () => {
        setOpenDataModal(!openDataModal);
    }

    const navigate = useNavigate();

    const workflowsService = new WorkflowsService();

    const runService = new RunService();

    const authService = new AuthService();

    useEffect(() => {
        setWorkflow({});
        setRun({});
        setRunAction({});
        setAction({});
        
        getWorkflows();
        getTables();
        refreshToken();
        getSchema();

        getDLBuckets();
    }, [run_id]);

    const getSingleData = ( ws) => {
        if(run_id){
            runService.getRun(run_id).then((response) => {
                setRun(response);
                let ras = response.actions;
                setRunActions(response.actions);
                console.log(response)
                setTimeout(() => {
                    setWorkflow(ws.find((w) => w.id === response.workflow_id));
                    console.log(ws.find((w) => w.id === response.workflow_id));
                }, 2000);
            });
        }
    };

    const getSchema = async () => {
        const s = await runService.getSchema();
        setSchema(s);
    }

    const refreshToken = async () => {
        authService.refresh_token();
    };

    const getTables = async () => {
        const tables = await runService.getTables('', '');
        setTables(tables);
    }

    const getWorkflows = async () => {
        const ws = await workflowsService.getWorkflows(0, 100);
        
        setWorkflows(ws.data);

        getSingleData(ws.data);
    }

    const getVariables = async (t) => {
        const vars = await runService.getVariables(t);
        setVariables(vars);
    }


    const resetTrinoFilters = () => {
        setTable('');
        setDatasets([]);
        setFilterVariable('');
        setFilterVariableValue('');
        setVariables([]);
        setTables([]);
        setFiles([]);
    }

    const getDLBuckets = async () => {
        const bs = await runService.getDataLakeBuckets();
        setBuckets(bs);
    }

    const getFiles = async (t) => {
        const fs = await runService.getFiles(t, '', '');
        console.log(JSON.stringify(fs));
        setFiles(fs);
    };

    const filterFiles = async () => {
        const fs = await runService.queryFiles(table, filterVariable, filterVariableValue);
        setFiles(fs);
    };

    const createRun = async (r) => {
        try{
            setRun({ ...run, started: true});
            const response = await runService.createRun(r);
            setRun({ ...run, id: response.id, started: true});
            navigate('/run/' + response.id);
            console.log(response);
            console.log({ ...run, id: response.id, started: true})
        }catch(e){
            console.log(e);
        }
    }

    const saveInput = async (r) => {
        try{
            setRunAction({ ...runAction, run_id: run.id, input: r.input.map(i => {
                return i.bucket ? i : {table, name: i, file: i, schema: schema}
            }) });
            const response = await runService.createAction(run.id, { ...runAction, run_id: run.id, input: JSON.stringify(r.input.map(i => {
                return i.bucket ? i : {table, name: i, file: i, schema: schema}
            })) });
            setRunAction({ ...runAction, run_id: run.id, id: response.id, input: r.input.map(i => {
                return i.bucket ? i : {table, name: i, file: i, schema: schema}
            }) });
            setDatasets([]);
        }catch(e){
            console.log(e);
        }
    }

    const gotoQB = async () => {
        const response = await runService.getDataForQb(run.id, runAction.id);
        document.qbwindow = window.open(response.step.metadata.url, '_blank');
        document.qbobserver = setInterval(() => {
            if(document.qbwindow && document.qbwindow.closed){
                reloadAction();
                clearInterval(document.qbobserver);
            }
        }, 2000);
    };

    const gotoDataAnalytics = async () => {
        const tokenResponse = await runService.getDAToken();
        const response = await runService.sendToDataAnalytics({
            workflow_id: run.workflow_id,
            run_id: run.id,
            step_id: runAction.id,
            function: action.method,
            token: localStorage.getItem('es_actual_token'),
            metadata: {
                "files": typeof runAction.input === 'string' ? JSON.parse(runAction.input): runAction.input
            }
        });
        document.dawindow = window.open(response.url, '_blank');
        
        document.daobserver = setInterval(() => {
            if(document.dawindow && document.dawindow.closed){
                reloadAction();
                clearInterval(document.daobserver);
            }
        }, 2000);

        
    }

    const selectBucket = async (b) => {
        setSelectedBucket(b);
        const fs = await runService.getDataLakeFolders(b);
        setSelectedFolder('');
        setDLFolders(fs);
    }

    const getSubfolders = async (bucket, folder) => {
        console.log(bucket, folder);
        const fs = await runService.getDataLakeInnerFolders(bucket, folder);
        setDLFolders(fs);
    }

    const saveRunAction = async() => {
        try{
            if(action.action_type === 'userInput'){
                const response = await runService.createAction(run.id, {...runAction, run_id: run.id});
                setRunActions([...runActions, response]);
            }
            toggleOpenActionModal();
        }catch(e){
            console.log(e);
        }
    };

    const reloadAction = async () => {
        const response = await runService.getAction(run.id, runAction.id);
        setRunAction(response);
        setRunActions([...runActions.filter(ra => ra.id !== response.id), response]);
    }

    const nextStep = async () => {
        try{
            const response = await runService.nextStep(run.id);
            setRun({
                ...run, 
                step: parseInt(run.step) + 1
            })
        }catch(e){
            console.log(e);
        }
    }

    const getInputFromQb = (stepId, actionId) => {
        let step = workflow.steps.filter(s => s.id === stepId)[0]; 
        if(step){
            let a = step.actions.find(a => a.id === actionId);
            if(a){
                let rac = runActions.find(ra => ra.action_id === a.id);
                if(rac && rac.value){
                    console.log(rac.value);
                    return JSON.parse(rac.value)?.data?.datalake;
                }
            }
        }
        return [];
    };

    const getTableData = async (source) => {
        let t = await runService.getTableData(table, source);
        if(t){
            setOpenFileData(t);
            setDisplayColumns(t.reduce((keys, row) => {
                if(!keys.includes(row.variable_name)){
                    keys.push(row.variable_name);
                }
                return keys;
            }, []));
            console.log(t);
        }
        else setOpenFileData([]);
    };

    const getInputFromDA = (stepId, actionId) => {
        let step = workflow.steps.find(s => s.id === stepId);
        if(step){
            let a = step.actions.find(a => a.id === actionId);
            if(a){
                let rac = runActions.find(ra => ra.action_id === a.id);
                if(rac && rac.value){
                    console.log(rac.value);
                    return JSON.parse(rac.value)?.results?.Output_datasets?.map(d => {
                        return {
                            bucket: 'common',
                            file: d.file
                        }
                    });
                }
            }
        }
        return [];    
    };

    const addToDatasetFromInput = (f) => {
        let exists = datasets.filter(d => d.file === f.object_name).length > 0;
        if(exists){
            setDatasets(datasets.filter(d => d.file !== f.object_name))
        }
        else{
            setDatasets([...datasets, {bucket: f.bucket_name, file: f.object_name}]);
        }
    };

    const displayResults = (results) => {
        if(Object.prototype.toString.call(results) === '[object Array]'){
            return results.map((rr, i) => {
                let keys = Object.keys(rr);
                return keys.map((key, i) => {
                    let result = rr[key];
                    if(Object.prototype.toString.call(result) === '[object Array]'){
                        return <p>{key}: <br />{result.map(kr => <>{kr} <Input type="select" onChange={(e) => changeVariableValue(e.target.value, kr)}>
                            <option value="">Select a Result Variable</option>
                            {
                                resultVariables.map(rv => (
                                    <option value={rv}>{rv}</option>
                                ))
                            }
                        </Input> <br /></>)}</p>;
                    }
                    else return <p>{key}: {result} <Input type="select" onChange={(e) => changeVariableValue(e.target.value, result)}>
                        <option value="">Select a Result Variable</option>
                        {
                            resultVariables.map(rv => (
                                <option value={rv}>{rv}</option>
                            ))
                        }
                    </Input></p>;
                })
            })
        }
        else{
            let keys = Object.keys(results);
            return keys.map((key, i) => {
                let result = results[key];
                if(Object.prototype.toString.call(result) === '[object Array]'){
                    return <p>{key}: <br />{result.map(kr => <>{kr} <Input type="select" onChange={(e) => changeVariableValue(e.target.value, kr)}>
                        <option value="">Select a Result Variable</option>
                        {
                            resultVariables.map(rv => (
                                <option value={rv}>{rv}</option>
                            ))
                        }
                    </Input><br /></>)}</p>;
                }
                else if(Object.prototype.toString.call(result) === '[object Object]'){
                    return <p>{key}: <br />{displayResults(result)}</p>;
                }
                else return <p>{key}: {result} <Input type="select" onChange={(e) => changeVariableValue(e.target.value, result)}>
                    <option value="">Select a Result Variable</option>
                    {
                        resultVariables.map(rv => (
                            <option value={rv}>{rv}</option>
                        ))
                    }
                </Input></p>;
            })
        }
    }

    const changeVariableValue = (v, kr) => {
        let vr = varResults;
        let index = vr.findIndex(vrr => vrr.key === v);
        if(index > -1){
            vr[index].value = (parseFloat(kr) ? parseFloat(kr) : kr);
        }
        else{
            vr.push({ key: v, value: (parseFloat(kr) ? parseFloat(kr) : kr) });
        }
        setVarResults(vr);
    }

    useEffect(() => {
        if(workflow && workflow.id && workflow.steps){
            let vs = [];
            for(let i = 0; i < workflow.steps.length; i ++){
                let s = workflow.steps[i];
                for(let j = 0; j < s.actions.length; j++){
                    let a = s.actions[j];
                    if(a.action_type === 'displayResult' && a.conditionals && a.conditionals.length > 0){
                        for(let k = 0; k < a.conditionals.length; k++){
                            let c = a.conditionals[k];
                            console.log(c);
                            if(c.variable && c.variable !== ''){
                                if(!vs.includes(c.variable)) vs.push(c.variable);
                            }
                            if(['variableEqualsToVariable', 'variableMoreThanVariable', 'variableLessThanVariable'].includes(c.condition)){
                                if(c.value && c.value !== ''){
                                    if(!vs.includes(c.value)) vs.push(c.value);
                                }
                            }
                        }
                        
                    }
                }
            }
            console.log(vs);
            setResultVariables(vs);
        }
    }, [workflow]);

    const calculateResultAction = (a) => {
        let totalRes = 0;
        for(let i = 0; i < a.conditionals.length; i++){
            let c = a.conditionals[i];
            if(c.variable && c.variable !== ''){
                let v = varResults.find(vr => vr.key === c.variable);
                console.log(v);
                console.log(c);
                if(c.condition === 'hasVariable' && v){
                    totalRes = totalRes + c.weight;
                }
                else if(v){
                    if(c.condition === 'hasVariable'){
                        if(v.value !== c.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableHasValue'){
                        if(v.value == c.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableMoreThan'){
                        if(v.value >= c.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableLessThan'){
                        if(v.value <= c.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableEqualsToVariable'){
                        let v2 = varResults.find(vr => vr.key === c.value);
                        if(v2 && v.value == v2.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableMoreThanVariable'){
                        let v2 = varResults.find(vr => vr.key === c.value);
                        if(v2 && v.value >= v2.value) totalRes = totalRes + c.weight;
                    }
                    else if(c.condition === 'variableLessThanVariable'){
                        let v2 = varResults.find(vr => vr.key === c.value);
                        if(v2 && v.value <= v2.value) totalRes = totalRes + c.weight;
                    }
                }
            }
        }
        if(totalRes >= a.weight_to_true){
            return true;
        }
        else{
            return false;
        }
    };

    const displayConditionalAnalysis = (a) => {
        let vars = a.conditionals.reduce((vs, c) => {
            if(c.variable && c.variable !== ''){
                if(!vs.includes(c.variable)) vs.push(c.variable);
            }
            return vs;
        }, []);
        return vars.map((v, i) => {
            let vr = varResults.find(vrr => vrr.key === v);
            if(vr){
                return <p>{v}: {vr.value}</p>;
            }
            else return <p>{v}: Not Found</p>;
        });
    };
    
    return (
        <div>
            <h1>Run a Workflow</h1>
            <Card>
                <CardBody>
                    <h3>Select the Workflow to run</h3>
                    <Input type="select" disabled={run.id} className="form-control" 
                        value={ run.workflow_id } id="workflowId" name="workflowId" onChange={(e) => {
                        setRun({ ...run, workflow_id: e.target.value, step: 0, action: 0});
                        setWorkflow(workflows.find((workflow) => workflow.id === e.target.value));
                    }}>
                        <option value="">Select a Workflow</option>
                        {workflows.map((workflow) => (
                            <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                        ))}
                    </Input>
                    <FormGroup>
                        <Label>Title</Label>
                        <Input type="text" id="title" name="title" defaultValue={run.title} onChange={(e) => {
                            setRun({ ...run, title: e.target.value });
                        }} />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardHeader color="primary">
                    <h3>Run Workflow {workflow && workflow.name} ({run.title})</h3>
                    {
                        !run.id && (
                            <Button className='float-end' color="primary" onClick={() => {
                                setRun({ ...run, started: true,
                                    step: 0,
                                    action: 0
                                 });
                                 createRun({ ...run, started: true,
                                    step: 0,
                                    action: 0
                                 });
                            }}>Start</Button>
                        )
                    }
                    
                </CardHeader>
                {
                    run.id && workflow && workflow.id && <>
                        <CardBody>
                            {
                                workflow.steps.filter(step => step.order < run.step ).map((step, index) => (
                                    <div key={index}>
                                        <h3>Step {index + 1}</h3>
                                        {
                                            step.actions.map((action, index) => {
                                                let ra = runActions.find(ra => ra.action_id === action.id);
                                                return <div key={index}>
                                                    <h4>Action {index + 1}</h4>
                                                    <p>{action.name}</p>
                                                    <p>{ action.action_type === 'user_input' && ra.value }

                                                        {
                                                            action.action === 'queryBuilderResponse' && ra.value && <>
                                                            <h3>Files</h3>
                                                            {
                                                                JSON.parse(ra.value)?.data?.datalake?.map(d => {
                                                                    return <p>Bucket: {d.bucket_name}, File: {d.object_name}</p>
                                                                })
                                                            }
                                                            </>
                                                            
                                                        }
                                                        {
                                                            action.action === 'dataAnalyticsResponse' && ra.value && JSON.parse(ra.value)?.results?.Output_datasets?.map(d => {
                                                                return <p>
                                                                    Bucket: common,
                                                                    File: {d.file}</p>
                                                            })
                                                        }
                                                        {
                                                            action.action === 'dataAnalyticsResponse' && ra.value && 
                                                                JSON.parse(ra.value)?.results?.test_results && 
                                                                displayResults(JSON.parse(ra.value)?.results?.test_results)
                                                        }
                                                    </p>
                                                </div>
                                            })
                                        }
                                    </div>
                                ))
                            }
                            <h3>{workflow.steps.find(step => step.order == run.step || run.step == 0) ? `Step ${parseInt(run.step) + 1}` : 'Completed'}</h3>
                            {
                                workflow.steps.find(step => step.order == (run.step || 0)) && 
                                workflow.steps.find(step => step.order == (run.step || 0)).actions.map((action, index) => {

                                    let ra = runActions.find(ra => ra.action_id === action.id);
                                    return <div key={index}>
                                        <h4>Action {index + 1}</h4>
                                        <p>{action.name}</p>
                                        <p>{action.description}</p>
                                        {
                                            (!ra || !ra.value) && <Button color="primary" onClick={() => {
                                                setAction(action);
                                                setRunAction(ra ? ra : {
                                                    action_id: action.id,
                                                    workflow_id: run.workflow_id,
                                                    action_id: action.id
                                                })
                                                toggleOpenActionModal();
                                            }}>Perform Action</Button>
                                        }
                                        {
                                            ra && ra.value && <p>{ action.action_type === 'user_input' && ra.value }

                                            {
                                                action.action === 'queryBuilderResponse' && ra.value && <>
                                                <h3>Files</h3>
                                                {
                                                    JSON.parse(ra.value)?.data?.datalake?.map(d => {
                                                        return <p>Bucket: {d.bucket_name}, File: {d.object_name}</p>
                                                    })
                                                }
                                                </>
                                                
                                            }
                                            {
                                                action.action === 'dataAnalyticsResponse' && ra.value && JSON.parse(ra.value)?.results?.Output_datasets?.map(d => {
                                                    return <p>
                                                        Bucket: common,
                                                        File: {d.file}</p>
                                                })
                                            }
                                            {
                                                action.action === 'dataAnalyticsResponse' && ra.value && 
                                                    JSON.parse(ra.value)?.results?.test_results && 
                                                    displayResults(JSON.parse(ra.value)?.results?.test_results)
                                            }
                                        </p>
                                        }
                                        {
                                            action.action_type === 'displayResult' && <>
                                                <h4>{action.name}</h4>
                                                <p>{action.description}</p>
                                                { displayConditionalAnalysis(action) }
                                                {
                                                    calculateResultAction(action) && <>
                                                        <Card color="success">
                                                            Valid {action.action}
                                                        </Card>
                                                    </>
                                                }
                                                {
                                                    !calculateResultAction(action) && <>
                                                        <Card color="danger">
                                                            Invalid {action.action}
                                                        </Card>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                })
                            }
                        </CardBody>
                        <CardFooter className='text-end'>
                            {
                                run.step < workflow.steps.length && <Button disabled={!(workflow.steps.find(step => step.order == (run.step || 0)) && 
                                    workflow.steps.find(step => step.order == (run.step || 0)).actions.filter(a => {
                                        return runActions.filter(ra => ra.action_id === a.id).length > 0;
                                    }).length > 0) } color="primary" onClick={() => {
                                    nextStep();
                                }}>Next Step</Button>
                            }
                            {
                                run.step >= workflow.steps.length && <Button color="success" onClick={() => {
                                    navigate('/run')
                                }}>Done</Button>
                            }
                        </CardFooter>
                    </>
                }
            </Card>
            <Modal size="lg" isOpen={openActionModal} toggle={toggleOpenActionModal}>
                <ModalHeader>{action.name}</ModalHeader>
                <ModalBody>
                    <h3>{action.name}</h3>
                    <p>{action.description}</p>
                    {
                        action.action_type === 'user_input' && (
                            <>
                                {
                                    action.action === 'text' && <FormGroup>
                                        <Input type="text" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.value });
                                        }} />
                                    </FormGroup>
                                }
                                {
                                    action.action === 'number' && <FormGroup>
                                        <Input type="number" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.value });
                                        }} />
                                    </FormGroup>
                                }
                                {
                                    action.action === 'date' && <FormGroup>
                                        <Input type="date" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.valueAsDate });
                                        }} />
                                    </FormGroup>
                                }
                                {
                                    action.action === 'options' && <FormGroup>
                                        <Input type="select" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.value });
                                        }}>
                                            <option value="">Select an option</option>
                                            {action.input && action.options.split('|').map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                }
                                {
                                    action.action === 'workflow' && <FormGroup>
                                        <Input type="select" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.value });
                                        }}>
                                            <option value="">Select a Workflow</option>
                                            {workflows.map((workflow) => (
                                                <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                }
                                {
                                    action.action === 'workflows' && <FormGroup>
                                        <Input type="select" id="input" name="input" value={runAction.value} onChange={(e) => {
                                            setRunAction({ ...runAction, value: e.target.value });
                                        }}>
                                            <option value="">Select a Workflow</option>
                                            {workflows.map((workflow) => (
                                                <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                }
                                {
                                    action.action_type === 'displayResult' && <>
                                        <h4>{action.name}</h4>
                                        <p>{action.description}</p>
                                        { displayConditionalAnalysis(action) }
                                        {
                                            calculateResultAction(action) && <>
                                                <Card color="success">
                                                    Valid {action.action}
                                                </Card>
                                            </>
                                        }
                                        {
                                            !calculateResultAction(action) && <>
                                                <Card color="danger">
                                                    Invalid {action.action}
                                                </Card>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        )
                    }
                    {
                        action.action_type === 'inputFromExternalService' && <>
                            {
                                action.action === 'queryBuilderResponse' &&  <>
                                {
                                    !runAction.input && <>
                                    <Row>
                                        <Col md="12">
                                        {
                                            datasets.length > 0 && <Col md="12">
                                                <Button block color="success" onClick={() => {
                                                    saveInput({ ...runAction, input: datasets });
                                                }}>Go on with selected files</Button>
                                            </Col>
                                        }
                                        {
                                            datasets.length === 1 && <Col md="12">
                                                <Button block color="warning" onClick={() => {
                                                    getTableData(datasets[0]);
                                                    toggleOpenDataModal();
                                                }}>Get Table Data</Button>
                                            </Col>
                                        }
                                        </Col>
                                        <Col md="6">
                                            <h4>Trino</h4>
                                            {
                                                tables && tables.length > 0 && <FormGroup>
                                                    <Label>Table</Label>
                                                    <Input className='form-control' type="select" id="table" name="table" value={table} onChange={(e) => {
                                                        setTable(e.target.value);
                                                        getVariables(e.target.value);
                                                        getFiles(e.target.value);
                                                    }}>
                                                        <option value="">Select a Table</option>
                                                        {tables.map((table) => (
                                                            <option key={table[0]} value={table[0]}>{table[0]}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            }
                                            {
                                                table && variables && variables.length > 0 && <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>Variable</Label>
                                                            <Input type="select" className='form-control' id="variable" name="variable" value={filterVariable} onChange={(e) => {
                                                                setFilterVariable(e.target.value);
                                                            }}>
                                                                <option value="">Select a Variable</option>
                                                                {variables.map((variable) => (
                                                                    <option key={variable} value={variable}>{variable}</option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>Value</Label>
                                                            <Input type="text" id="value" name="value" value={filterVariableValue} onChange={(e) => {
                                                                setFilterVariableValue(e.target.value);
                                                            }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Button color="info" onClick={() => {
                                                            filterFiles();
                                                        }}>Filter</Button>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                table && files && files.length > 0 && <Row>
                                                    <Col md="12">
                                                        <Button color="primary" onClick={() => {
                                                            setDatasets(...datasets.filter(d => d.bucket), ...files.map(f => f[0]));
                                                        }
                                                        }>Select All</Button>
                                                        <Button color="warning" onClick={() => {
                                                            setDatasets([...datasets.filter(d => d.bucket)]);
                                                        }
                                                        }>Clear</Button>
                                                        
                                                    </Col>
                                                    {
                                                        files.map((f, index) => (
                                                            <Col md={3} key={index}>
                                                                <p style={{ wordBreak: 'break-all' }}>{f[0]}</p>
                                                                <Button color={ datasets.filter(d => d === f[0]).length > 0 ? 'primary' : 'secondary' } onClick={() => {
                                                                    if(datasets.filter(d => d === f[0]).length > 0)
                                                                        setDatasets(datasets.filter(d => d !== f[0]));
                                                                    else
                                                                        setDatasets([...datasets, f[0]]);
                                                                }}>Select</Button>
                                                            </Col>
                                                        ))
                                                    }

                                                </Row>
                                            }
                                        </Col>
                                        <Col md="6">
                                            <h4>Data Lake</h4>
                                            <FormGroup>
                                                <Label>Select Bucket</Label>
                                                <Input className="form-control" type="select" id="bucket" name="bucket" value={selectedBucket} onChange={(e) => {
                                                    selectBucket(e.target.value)
                                                }}>
                                                    <option value="">Select a Bucket</option>
                                                    {buckets && buckets.map((bucket) => (
                                                        <option key={bucket._name} value={bucket._name}>{bucket._name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                            <Row>
                                                
                                                <Col md="6">
                                                    <Button block color="warning">Clear</Button>
                                                </Col>
                                            </Row>
                                            {
                                                selectedBucket && dlfolders.length > 0 && <>
                                                    <Row>
                                                        {
                                                            selectedFolder && <Col md="6">
                                                                <Card>
                                                                    <CardBody>
                                                                        <p>Previous Folder</p>
                                                                    </CardBody>
                                                                    <CardHeader>
                                                                        <Button color="warning" onClick={() => {
                                                                            let folderArray = selectedFolder.split('/');
                                                                            folderArray.pop();
                                                                            getSubfolders(selectedBucket, folderArray.join('/'));
                                                                            setSelectedFolder(folderArray.join('/'))
                                                                        }}>Back</Button>
                                                                    </CardHeader>
                                                                </Card>
                                                            </Col>
                                                        }
                                                        {
                                                            dlfolders && dlfolders.length > 0 && dlfolders.map((folder, index) => (
                                                                <Col md="6" key={index}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <p>{folder._object_name}</p>
                                                                        </CardBody>
                                                                        <CardHeader>
                                                                            <Button color={ datasets.filter((d) => 
                                                                                d.file === folder._object_name).length > 0 ? 'primary' : 'secondary'  } 
                                                                                onClick={() => {
                                                                                if(!folder._storage_class){
                                                                                    getSubfolders(selectedBucket, folder._object_name);
                                                                                    setSelectedFolder(folder._object_name);
                                                                                }
                                                                                else{
                                                                                    if(datasets.filter((d) =>
                                                                                        d.file === folder._object_name).length > 0)
                                                                                        setDatasets(datasets.filter((d) =>
                                                                                            d.file !== folder._object_name));
                                                                                    else
                                                                                        setDatasets([...datasets, {
                                                                                            bucket: selectedBucket,
                                                                                            file: folder._object_name
                                                                                        }]);
                                                                                }
                                                                            }}>Select</Button>
                                                                        </CardHeader>
                                                                    </Card>
                                                                </Col>
                                                            ))
                                                        }
                                                        
                                                    </Row>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                        
                                    </>
                                }
                                {
                                    runAction.input && <>
                                        <Row>
                                            <Col md="12">
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                datasets.map((d, index) => (
                                                    <Col md={3} key={index}>
                                                        <p style={{ wordBreak: 'break-all' }}>{d.bucket ? d.file : d}</p>
                                                    </Col>
                                                ))
                                            }
                                            <Col md="6">
                                                <Button block color="success" onClick={() => {
                                                    gotoQB();
                                                }}>Go to Query Builder</Button>
                                            </Col>
                                            <Col md="3">
                                                <Button block color="warning" onClick={() => {
                                                    reloadAction([]);
                                                }}>Refresh</Button>
                                            </Col>
                                            <Col md="3">
                                                <Button block color="danger" onClick={() => {
                                                    setRunAction({ ...runAction, input: null });
                                                    setRunActions([...runActions.filter(ra => ra.id !== runAction.id), { ...runAction, input: null } ]);
                                                    saveInput({ ...runAction, input: null });
                                                }}>Clear Input</Button>
                                            </Col>
                                            {
                                                runAction.value && <Col md="12">
                                                    <Card color="warning">The function has been completed, you can go on</Card>
                                                </Col>
                                            }
                                            <Col md="12">
                                                
                                                <p>When you click this button, a new tab will open with ΔαταΑναλυτιψσ 
                                                    service and the input will automatically be the files you selected.</p>
                                                <p>When you complete the tasks there, you should close the window and the action
                                                    will be updated automatically. If not, click the refresh button bellow
                                                </p>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                    
                                </> 
                            }
                            {
                                action.action === 'dataAnalyticsResponse' &&  <>
                                {
                                    !runAction.input && <>
                                        <p>You have to select {
                                            analytics_functions.filter(af => af.name === action.method)[0].expected_input
                                            }</p>
                                        {
                                            runActions.filter(ra => ra.value).map(ra => {
                                                let cstep = workflow.steps.filter(s => s.actions.filter(sa => sa.id === ra.action_id).length > 0)[0];
                                                let aa = cstep?.actions.filter(a => a.id === ra.action_id)[0];
                                                if(aa.action === 'queryBuilderResponse'){
                                                    return <>
                                                        <p>You have those files as input from action: {aa.name}</p>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Bucket</th>
                                                                    <th>File</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getInputFromQb(aa.workflow_step_id, aa.id).map((f, index) => (
                                                                        <tr key={index}>
                                                                            <td>{f.bucket_name}</td>
                                                                            <td>{f.object_name}</td>
                                                                            <td><Button color={ datasets.filter((d) => 
                                                                                    d.file === f.object_name).length > 0 ? 'primary' 
                                                                                    : 'secondary'  } 
                                                                                    onClick={() => {
                                                                                        addToDatasetFromInput(f)
                                                                                    }}
                                                                                    >Select</Button></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </>
                                                }
                                                else if(aa.action === 'dataAnalyticsResponse'){
                                                    return <>
                                                        <p>You have those files as from action: {aa.name}</p>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Bucket</th>
                                                                    <th>File</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getInputFromDA(aa.workflow_step_id, aa.id).map((f, index) => (
                                                                        <tr key={index}>
                                                                            <td>Common</td>
                                                                            <td>{f.file}</td>
                                                                            <td><Button color={ datasets.filter((d) => 
                                                                                    d.file === f.object_name).length > 0 ? 'primary' 
                                                                                    : 'secondary'  } 
                                                                                    onClick={() => {
                                                                                        addToDatasetFromInput({
                                                                                            object_name: f.file,
                                                                                            bucket_name: 'common'
                                                                                        })
                                                                                    }}
                                                                                    >Select</Button></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </>
                                                }
                                            })
                                        }
                                        
                                        <h4>Data Lake</h4>
                                            <FormGroup>
                                                <Label>Select Bucket</Label>
                                                <Input className="form-control" type="select" id="bucket" name="bucket" value={selectedBucket} onChange={(e) => {
                                                    selectBucket(e.target.value)
                                                }}>
                                                    <option value="">Select a Bucket</option>
                                                    {buckets && buckets.map((bucket) => (
                                                        <option key={bucket._name} value={bucket._name}>{bucket._name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                            {
                                                datasets.length > 0 && <Col md="12">
                                                    <Button block color="success" onClick={() => {
                                                        saveInput({ ...runAction, input: datasets });
                                                    }}>Go on with selected files</Button>
                                                </Col>
                                            }
                                            {
                                                selectedBucket && dlfolders.length > 0 && <>
                                                    <Row>
                                                        {
                                                            selectedFolder && <Col md="6">
                                                                <Card>
                                                                    <CardBody>
                                                                        <p>Previous Folder</p>
                                                                    </CardBody>
                                                                    <CardHeader>
                                                                        <Button color="warning" onClick={() => {
                                                                            let folderArray = selectedFolder.split('/');
                                                                            folderArray.pop();
                                                                            getSubfolders(selectedBucket, folderArray.join('/'));
                                                                            setSelectedFolder(folderArray.join('/'))
                                                                    }}>Back</Button>
                                                                </CardHeader>
                                                            </Card>
                                                        </Col>
                                                        }
                                                        {
                                                            dlfolders && dlfolders.length > 0 && dlfolders.map((folder, index) => (
                                                                <Col md="6" key={index}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <p>{folder._object_name}</p>
                                                                        </CardBody>
                                                                        <CardHeader>
                                                                            <Button color={ datasets.filter((d) => 
                                                                                d.file === folder._object_name).length > 0 ? 'primary' : 'secondary'  } 
                                                                                onClick={() => {
                                                                                if(!folder._storage_class){
                                                                                    getSubfolders(selectedBucket, folder._object_name);
                                                                                    setSelectedFolder(folder._object_name);
                                                                                }
                                                                                else{
                                                                                    if(datasets.filter((d) =>
                                                                                        d.file === folder._object_name).length > 0)
                                                                                        setDatasets(datasets.filter((d) =>
                                                                                            d.file !== folder._object_name));
                                                                                    else
                                                                                        setDatasets([...datasets, {
                                                                                            bucket: selectedBucket,
                                                                                            file: folder._object_name,
                                                                                            group_name: ''
                                                                                        }]);
                                                                                }
                                                                            }}>Select</Button>
                                                                        </CardHeader>
                                                                    </Card>
                                                                </Col>
                                                            ))
                                                        }
                                                        
                                                    </Row>
                                                </>
                                            }
                                    </>
                                }
                                {
                                    runAction.input && <>
                                        <Row>
                                            <Col md="12">
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                datasets.map((d, index) => (
                                                    <Col md={3} key={index}>
                                                        <p style={{ wordBreak: 'break-all' }}>{d.bucket ? d.file : d}</p>
                                                    </Col>
                                                ))
                                            }
                                            <Col md="9">
                                                <Button block color="success" onClick={() => {
                                                    gotoDataAnalytics();
                                                }
                                                }>Go to Data Analytics</Button>
                                            </Col>
                                            <Col md="3">
                                                <Button block color="warning" onClick={() => {
                                                    reloadAction([]);
                                                }}>Refresh</Button>
                                            </Col>
                                            {
                                                runAction.value && <Col md="12">
                                                    <Card color="warning">The function has been completed, you can go on</Card>
                                                </Col>
                                            }
                                            <Col md="12">
                                                <p>
                                                    When you click this button, a new tab will open with Data Analytics 
                                                    service and the input will automatically be the files you selected.
                                                </p>
                                                <p>
                                                    When you complete the tasks there, you should close the window and the action
                                                    will be updated automatically. If not, click the refresh button bellow
                                                </p>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                    
                                </> 
                            }
                        </>
                    }
                    {
                        action.action_type === 'displayResult' && <>
                            <h4>{action.name}</h4>
                            <p>{action.description}</p>
                            { displayConditionalAnalysis(action) }
                            {
                                calculateResultAction(action) && <>
                                    <Card color="success">
                                        Valid {action.action}
                                    </Card>
                                </>
                            }
                            {
                                !calculateResultAction(action) && <>
                                    <Card color="danger">
                                        Invalid {action.action}
                                    </Card>
                                </>
                            }
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        saveRunAction();
                    }}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal size="xl" isOpen={openDataModal} toggle={toggleOpenDataModal}>
                <ModalHeader>
                    <h3>View Data</h3>
                </ModalHeader>
                <ModalBody style={{overflowX: 'scroll'}}>
                    <Table striped>
                        <thead>
                            {
                                openFileData && openFileData.length > 0 && displayColumns.length > 0 && displayColumns.map((key, index) => (
                                    <th key={index}>{key}</th>
                                ))
                            }
                        </thead>
                        <tbody>
                            {
                                openFileData && openFileData.reduce((rowNumbers, row) => {
                                    if(!rowNumbers.includes(row.row)){
                                        rowNumbers.push(row.row);
                                    }
                                    return rowNumbers;
                                }, []).map((rowNumber, index) => {
                                    return <tr key={index}>
                                        {
                                            displayColumns.map((key, index) => {
                                                let value = openFileData.filter(row => row.row === rowNumber && row.variable_name === key)[0];
                                                return <td key={index+rowNumber}>{value ? value.variable_value : ''}</td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default RunWorkflow;