import React, { useEffect, useState } from "react";
import CustomDataTable from "../../components/datatable";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { MoreVertical, Archive } from "react-feather";
import DeleteWorkflowModal from "./Modals/DeleteWorkflow";
import { WorkflowsService } from "../../services/workflowsService";

const ListWorkflows = () => {
    const [workflowList, setWorkflowList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const workflowsService = new WorkflowsService();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: false,
        },
        {
            name: "Number of steps",
            selector: (row) => row.steps.length,
            sortable: false,
        },
        {
            name: "Created At",
            cell: (row) => <>{row.created_at.split("T")[0]}</>,
            sortable: (row) => row.created_at,
        },
        {
            name: "Updated At",
            cell: (row) => <>{row.updated_at.split("T")[0]}</>,
            sortable: (row) => row.updated_at,
        },
        {
            name: "Actions",
            width: "300px",
            cell: (row) => {
                const Child = () => {
                    return (
                        <>
                           
                            <Button type="button" color="danger" onClick={() => deleteWorkflow(row.id)}>Delete</Button>
                            <UncontrolledDropdown>
                                <DropdownToggle className="pe-1" tag="span">
                                    <MoreVertical size={15} />
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem tag="a" className="w-100" href={`/editworkflow/${row.id}`}>
                                        <Archive size={15} />
                                        <span className="align-middle ms-50"> Edit Workflow</span>
                                    </DropdownItem>
                                    <DropdownItem tag="a" className="w-100" >
                                        <Archive size={15} />
                                        <span className="align-middle ms-50"> Copy Workflow</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    );
                };
                return <Child />;
            },
            sortable: false,
        },
    ];

    async function getWorkflowsCallback( skip = 0, limit = 100) {
        setLoading(true);

        try {
            const ws = await workflowsService.getWorkflows(skip, limit);
            setTotalDataCount(ws.paging.count);
            setWorkflowList(ws.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getWorkflows = async (skip = 0, limit = 100) => {
        try {
            const response = await workflowsService.getWorkflows(skip, limit);
            console.log(response.data);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        (async () => {
            await getWorkflowsCallback();
        })();
    }, []);

    const deleteWorkflow = async (workflowId) => {
        if (window.confirm("Are you sure you want to delete this workflow?")) {
            try {
                await workflowsService.deleteWorkflow(workflowId);
                await getWorkflowsCallback();
            } catch (e) {
                console.log(e);
            }
        }
    }

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <CustomDataTable
                        data={workflowList}
                        columns={columns}
                        tableName={"Active Workflows"}
                        hasServerPagination={true}
                        getDataCallback={getWorkflows}
                        totalDataCount={totalDataCount}
                    />
                </>
            )}
        </>
    );
};

export default ListWorkflows;
